import 'PublicPath';

import { renderSsrClient } from 'rendering/client/renderSsrClient';

import { App } from './app';
import { routeObjects } from './routing/route-objects';
import { configureMainStore } from './store/configureMainStore';

renderSsrClient({
  Component: App,
  routeObjects,
  createStore: configureMainStore,
});
