import styled from 'styled-components';

import { colors, cssBreakpoints, transition } from 'theme/theme';

import {
  dropdownStateClosedClass,
  dropdownStateOpenClass,
} from './dropdownClasses';
import {
  dropdownBrandBlueWhenOpenVar,
  dropdownCalloutGreyWhenOpenVar,
  dropdownOneWhenClosedMinusOneWhenOpenVar,
  dropdownOneWhenClosedZeroWhenOpenVar,
} from './dropdownCssVariables';

/**
 * Do not add `position: relative` to the details.
 *
 * In some cases, such as the mobile DropdownFoldMenu, we need the menu to align
 * with the parent container, not the `details` tag
 *
 * Instead, add `position: relative` to the desired wrapping element
 */
export const DropdownDetails = styled.details`
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    position: relative;
  }

  display: inline-flex;

  ${dropdownOneWhenClosedMinusOneWhenOpenVar}: 1;
  ${dropdownOneWhenClosedZeroWhenOpenVar}: 1;

  &[open] {
    ${dropdownOneWhenClosedMinusOneWhenOpenVar}: -1;
    ${dropdownOneWhenClosedZeroWhenOpenVar}: 0;
    ${dropdownBrandBlueWhenOpenVar}: ${colors.brandBlue};
    ${dropdownCalloutGreyWhenOpenVar}: ${colors.calloutGrey};
  }

  &.${dropdownStateOpenClass} {
    menu {
      transition: ${transition};
      transition-property: transform;
    }
  }

  &.${dropdownStateClosedClass} {
    menu {
      visibility: hidden;

      @media all and (max-width: ${cssBreakpoints.mdDown}) {
        transform: translateY(-10%);
      }

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        transform: translateY(-10px);
      }
    }
  }
`;

export const DropdownSummary = styled.summary`
  position: relative;
  z-index: 100;

  &::marker {
    content: '';
  }

  &::-webkit-details-marker /* Safari */ {
    display: none;
  }
`;
