import { RefObject, useCallback, useEffect } from 'react';

import {
  dropdownStateClosedClass,
  dropdownStateOpenClass,
} from './dropdownClasses';

type Args = {
  detailsElRef: RefObject<HTMLDetailsElement>;
};

/**
 * Unfortunately, as of the time of writing this component, the `<summary>` + `<details>` combo
 * does behave properly with animations or transitions.
 *
 * This hook enriches the UI by adding "state" classes to the `<summary>` element to allow for a smooth transition effect.
 *
 * This technique allows us to have a functioning component without JS, that then gets enriched
 * once the page is fully loaded.
 */
export function useDropdownTransitionClasses({ detailsElRef }: Args) {
  useEffect(() => {
    window.requestAnimationFrame(() => {
      const detailsEl = detailsElRef.current;

      if (detailsEl && !detailsEl.open) {
        detailsEl.classList.add(dropdownStateClosedClass);
      }
    });
  }, [detailsElRef]);

  const onToggleAnimation = useCallback(
    (open: boolean) => {
      const detailsEl = detailsElRef.current;
      if (!detailsEl) return;

      if (!open) {
        detailsEl.classList.remove(dropdownStateOpenClass);
        detailsEl.classList.add(dropdownStateClosedClass);
        return;
      }

      setTimeout(() => {
        detailsEl.classList.replace(
          dropdownStateClosedClass,
          dropdownStateOpenClass,
        );
      }, 10);
    },
    [detailsElRef],
  );

  return { onToggleAnimation };
}
