import { Store } from '@reduxjs/toolkit';
import queryString from 'query-string';

import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';
import { StaffStoreState } from 'modules/staff/store/StaffStoreState';
import { MainStoreState } from 'store/MainStoreState';
import { setABExperimentBucket } from 'store/ducks/userEnvironment.actions';

// Accept a query param to override an AB experiment bucket:
// ?ab_test[button_color]=red
export function renderingOverrideClientABExperiments(
  store: Store<MainStoreState | StaffStoreState>,
  userEnvironment: ApiUserEnvironment,
) {
  const queryParams = queryString.parse(window.location.search);
  const overrides = Object.keys(queryParams).reduce((acc, param) => {
    const matches = param.match(/^ab_test\[([^\]]+)\]$/);

    if (matches) {
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
      acc.push({ id: matches[1], bucket: queryParams[param] });
    }

    return acc;
  }, []);
  overrides.forEach(({ id, bucket }) => {
    const experiment = userEnvironment.environment.abExperiments.find(
      (e) => e.id === id,
    );

    if (!experiment || experiment.bucket !== bucket) {
      // @ts-expect-error fix types
      store.dispatch(setABExperimentBucket(id, bucket));
    }
  });
}
