/*
 * By using CSS variables we remove the need for JS to communicate between components
 * through transient properties, relying only on CSS.
 *
 * This effectively means that the component is immediately responsive to user input, which is important for a good UX,
 * which in turn impacts our lighthouse score.
 *
 * As a bonus, by not relying on JS, we can use the component on AMP pages
 */
export const dropdownOneWhenClosedMinusOneWhenOpenVar =
  '--dropdown-one-closed-minus-one-open';
export const dropdownOneWhenClosedZeroWhenOpenVar =
  '--dropdown-one-closed-zero-open';
export const dropdownBrandBlueWhenOpenVar = '--dropdown-brand-blue-when-open';
export const dropdownCalloutGreyWhenOpenVar =
  '--dropdown-callout-grey-when-open';
