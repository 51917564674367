import { Store } from '@reduxjs/toolkit';

import { StaffStoreState } from 'modules/staff/store/StaffStoreState';
import { MainStoreState } from 'store/MainStoreState';

import { onRenderingClientUserEnvironmentLoaded } from './onRenderingClientUserEnvironmentLoaded';
import { renderingClientPerformanceMark } from './renderingClientPerformanceMark';
import { renderingGetClientPreloadedUserEnvironment } from './renderingGetClientPreloadedUserEnvironment';
import { renderingWaitForClientUserEnvironmentToLoad } from './renderingWaitForClientUserEnvironmentToLoad';

type Args = {
  store: Store<MainStoreState | StaffStoreState>;
};

export async function renderingLoadClientUserEnvironment({ store }: Args) {
  renderingClientPerformanceMark('Before setting login state');

  const preloadedUserEnvironment =
    renderingGetClientPreloadedUserEnvironment(store);

  if (preloadedUserEnvironment) {
    onRenderingClientUserEnvironmentLoaded({
      store,
      userEnvironment: preloadedUserEnvironment,
    });
  } else {
    const userEnvironment = await renderingWaitForClientUserEnvironmentToLoad();
    onRenderingClientUserEnvironmentLoaded({ store, userEnvironment });
  }
}
