import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHasUnmountedRef } from './useHasUnmounted';
import { useIsFirstRenderRef } from './useIsFirstRenderRef';
import { useLatest } from './useLatest';
import { useStableDeepObject } from './useStableDeepObject';

export type UseFetchFnClearCacheOptions = {
  keepKeyFn?: (key: string) => boolean;
};

export type UseFetchFnMeta<TData> = {
  currentCacheKey: string;
  loading: boolean;
  error: Error | undefined;
  /**
   * Used to update the cached value when an update happens, and we wish to avoid having to reload this endpoint for better UX
   */
  updateCache: (data: TData) => void;
  reload: () => Promise<void>;
  /**
   * Allow clearing either the whole cache or specific keys
   */
  clearCache: (options?: UseFetchFnClearCacheOptions) => void;
};

type Args<TData, TArgs extends unknown[]> = {
  /**
   * `fn` must be a stable reference - either use `useCallback` or declare it outside the component
   *
   * An unstable `fn` will cause an infinite fetch loop.
   *
   * If `fn` changes the cache will be cleared because we can't guarantee that the new `fn` will return the same response
   * given a set of `args`
   */
  fn: (...params: TArgs) => Promise<TData>;
  /**
   * When `cacheKey` is not provided these arguments are serialized with `JSON.stringify()`. This means that functions
   * and symbols are converted to `null`, thus, they're not relevant to the cache.
   *
   * The `args` are also stabilized using `deepEqual` because it's very easy to miss a memoization and cause infinite
   * render loops. If you're concerned that your use case might be impacted by deep object comparisons, make sure to
   * memoize the arguments.
   */
  args: TArgs;
  /**
   * Used to inject data during SSR
   */
  initialCacheStorageData?: Record<string, TData>;
  /**
   * `cacheKey` is automatically generated using `args` if not provided
   */
  cacheKey?: string;
};

/**
 * Call and store results for `fn` given a set of args.
 *
 * The results are cached based on the `cacheKey` for a better user experience until the hook is unmounted. This means
 * that going to a different page then coming back will not keep the cache alive.
 *
 * This caching strategy is used to avoid stale caching, while also helping improve User Experience - a user switching
 * between filters, or tabs for example, will be able to immediately see the data that has already been loaded.
 */
export function useFetchFn<TData, TArgs extends unknown[]>({
  fn,
  args: unstableArgs,
  initialCacheStorageData,
  cacheKey: cacheKeyProp,
}: Args<TData, TArgs>): [TData | undefined, UseFetchFnMeta<TData>] {
  // ==============================
  // 0. Stabilize data
  // ==============================

  const stabilizedArgs = useStableDeepObject(unstableArgs);

  // ==============================
  // 1. Cache
  // ==============================

  const [cacheStorage, setCacheStorage] = useState<Record<string, TData>>(
    initialCacheStorageData || {},
  );

  const cacheKey = useMemo(
    () => cacheKeyProp || JSON.stringify(stabilizedArgs),
    [cacheKeyProp, stabilizedArgs],
  );

  const cachedData = cacheKey ? cacheStorage[cacheKey] : undefined;

  const clearCache = useCallback((options?: UseFetchFnClearCacheOptions) => {
    const keepKeyFn = options?.keepKeyFn;

    setCacheStorage((currentCacheStorage) => {
      if (!keepKeyFn) return {};

      return Object.keys(currentCacheStorage).reduce((acc, key) => {
        if (!keepKeyFn(key)) return acc;
        return { ...acc, [key]: currentCacheStorage[key] };
      }, {});
    });
  }, []);

  const updateCache = useCallback(
    (newData: TData) => {
      setCacheStorage((currentCacheStorage) => ({
        ...currentCacheStorage,
        [cacheKey]: newData,
      }));
    },
    [cacheKey],
  );

  const { isFirstRenderRef } = useIsFirstRenderRef();
  useEffect(() => {
    if (isFirstRenderRef.current) return;
    setCacheStorage({});
  }, [fn, isFirstRenderRef]);

  // ==============================
  // 2. Load
  // ==============================

  const hasUnmountedRef = useHasUnmountedRef();

  const [error, setError] = useState<Error>();
  const hasErrorRef = useLatest(Boolean(error));

  const [loadFnRunning, setLoadFnRunning] = useState(false);

  // Don't use just `useState(calculate default value)` because the cache key
  // can change, and we need `loading` to be calculated immediately
  const loading =
    loadFnRunning || (typeof cachedData === 'undefined' && !error);

  /**
   * `promiseRef` is used to keep a reference to the last promise in use, making it possible to recognize that a new `fn()`
   * has been called and that the previous one should be halted.
   */
  const promiseRef = useRef<Promise<TData>>();

  const load = useCallback(async () => {
    setLoadFnRunning(true);
    if (hasErrorRef.current) setError(undefined);

    const promise = fn(...stabilizedArgs);
    promiseRef.current = promise;

    try {
      const newData = await promise;
      if (hasUnmountedRef.current || promiseRef.current !== promise) return;

      updateCache(newData);
    } catch (newError) {
      setError(newError as Error);
    } finally {
      setLoadFnRunning(false);
    }
  }, [fn, stabilizedArgs, hasUnmountedRef, hasErrorRef, updateCache]);

  useEffect(() => {
    if (cachedData) return;

    load();
  }, [load, cachedData]);

  // ==============================
  // Return
  // ==============================

  const meta = useMemo(
    () =>
      ({
        loading,
        error,
        updateCache,
        reload: load,
        clearCache,
        currentCacheKey: cacheKey,
      }) satisfies UseFetchFnMeta<TData>,
    [loading, error, updateCache, load, clearCache, cacheKey],
  );

  return [cachedData, meta];
}
