import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

export function getIsNoAdPage(content: CmsApiPage, isLoggedIn: boolean) {
  if (content) {
    // no ads on home page when logged out
    if (['en', 'es', 'pt'].includes(content.url) && !isLoggedIn) {
      return true;
    }
    // no ads on idealist days page
    if (content.url && content.url.includes('en/days/home')) {
      return true;
    }
  }
  return false;
}
