import { AnimatedIconLoadable } from 'components/AnimatedIcon/AnimatedIconLoadable';
import { AnimatedIconName } from 'components/AnimatedIcon/AnimatedIconName';
import { ButtonOrLinkOwnProps } from 'components/ButtonOrLink/ButtonOrLink';
import {
  ButtonOrLinkData,
  ButtonOrLinkDataProps,
} from 'components/ButtonOrLink/ButtonOrLinkData';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { LottieAnimationOptions } from 'components/LottieAnimation/LottieAnimationOptions';
import { Color } from 'theme/theme';

import { ButtonIconWrapper, StyledButtonOrLink } from './Button.styled';
import { ButtonSize, ButtonVariant } from './Button.types';

const DEFAULT_VARIANT = 'primary';

export type ButtonOwnProps = ButtonOrLinkOwnProps & {
  icon?:
    | { type: 'normalized'; name: IconName; color?: Color; size?: number }
    | {
        type: 'animated';
        name: AnimatedIconName;
        options?: LottieAnimationOptions;
      };
  iconPosition?: 'left' | 'right';
  size?: ButtonSize;
  variant?: ButtonVariant;
  width?: number;
  fullWidth?: boolean;
  height?: number;
  noPadding?: boolean;
};

type Props = ButtonOrLinkDataProps<ButtonOwnProps>;

export function Button({
  id,
  className,
  children,

  icon,
  iconPosition = 'left',
  size,
  tabIndex,
  variant,
  width,
  fullWidth,
  height,
  noPadding,
  title,
  'data-qa-id': dataQaId,
  'aria-label': ariaLabel,
  style,
  ...otherProps
}: Props) {
  const activeVariant = variant || DEFAULT_VARIANT;
  const data = (
    'data' in otherProps ? otherProps.data : otherProps
  ) as ButtonOrLinkData;

  return (
    <StyledButtonOrLink
      data={data}
      id={id}
      className={`${className || ''} ${activeVariant} ${size || 'medium'} ${
        fullWidth ? 'full-width' : ''
      } ${noPadding ? 'no-padding' : ''}`}
      style={{ width, height, ...style }}
      title={title}
      tabIndex={tabIndex}
      data-qa-id={dataQaId}
      aria-label={ariaLabel}
    >
      {icon && (
        <ButtonIconWrapper $position={iconPosition || 'left'}>
          {icon.type === 'normalized' ? (
            <Icon
              colorName={icon.color}
              name={icon.name}
              size={icon.size || 20}
            /> // Use a loadable instead of the component directly to avoid bloating the bundle
          ) : (
            // Add wrapping div to avoid CLS
            <div style={{ width: 20, height: 20 }}>
              <AnimatedIconLoadable
                name={icon.name}
                size={20}
                options={icon.options}
              />
            </div>
          )}
        </ButtonIconWrapper>
      )}

      {children && <div style={{ display: 'inline-flex' }}>{children}</div>}
    </StyledButtonOrLink>
  );
}
