import { ApiActionType } from 'modules/listing/api/volop/types/ApiActionType';

import { VOLOP_SOURCES } from './volopSources';

export const VOLOP_SOURCE_FOR_ACTION_TYPE = {
  NONE: [...Object.keys(VOLOP_SOURCES)],
  VOLOP: [
    'NONE',
    'IDEALIST',
    'VOLUNTEERMATCH',
    'POINTS_OF_LIGHT',
    'AARP',
    'DO_SOMETHING',
    'NEWYORKCARES',
    'VOLUNTEERGOV',
    'TRANSFORMA',
    'GOLDEN',
  ],
  LOAN: [],
  DONATION: [
    'NONE',
    'DONORS_CHOOSE',
    'GLOBAL_GIVING',
    'DO_SOMETHING',
    'GRAPEVINE',
  ],
  OTHER: ['IDEALIST', 'DO_SOMETHING'],
  PETITION: ['MOVE_ON', 'DO_SOMETHING'],
} as const satisfies Record<ApiActionType | 'NONE', string[]>;
