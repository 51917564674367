import { CSSProperties, useRef } from 'react';

type Props = {
  src: string;
  srcSet?: string;
  sourcesData?:
    | { media: string; srcSet: string }[]
    | readonly { media: string; srcSet: string }[];
  alt: string;
  height?: number;
  width?: number;
  expandSize?: boolean;
  'data-qa-id'?: string;
  objectFit?: 'cover' | 'contain';
  objectPosition?: CSSProperties['objectPosition'];
  aspectRatio?: { width: number; height: number };
  round?: boolean;
  eagerLoad?: boolean;
  style?: CSSProperties;
};

export function Image({
  src,
  srcSet,
  sourcesData,
  alt,
  width,
  height,
  expandSize,
  objectFit = 'contain',
  objectPosition,
  aspectRatio,
  round,
  eagerLoad,
  'data-qa-id': dataQaId,
  style,
}: Props) {
  const ref = useRef<HTMLImageElement>(null);

  const imgTag = (
    <img
      ref={ref}
      src={src}
      srcSet={srcSet && srcSet !== src ? srcSet : undefined}
      alt={alt}
      width={expandSize ? '100%' : width}
      height={expandSize ? '100%' : height}
      data-qa-id={dataQaId}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit,
        objectPosition,
        borderRadius: round ? '100%' : undefined,
        aspectRatio: aspectRatio
          ? `${aspectRatio.width} / ${aspectRatio.height}`
          : undefined,
        ...style,
      }}
      // Eager is the default browser loading strategy
      loading={eagerLoad ? undefined : 'lazy'}
    />
  );

  if (!sourcesData || sourcesData.length < 2) return imgTag;

  return (
    <picture
      style={{
        width: expandSize ? '100%' : undefined,
        height: expandSize ? '100%' : undefined,
      }}
    >
      {sourcesData.map((sourceData) => (
        <source
          key={sourceData.media}
          srcSet={sourceData.srcSet}
          media={sourceData.media}
        />
      ))}

      {imgTag}
    </picture>
  );
}
