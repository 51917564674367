import { create } from 'zustand';

import { onClientHydrated } from './renderingStateStore';

const ssrApiDataStore = create<{ data: Record<string, unknown> }>(() => ({
  data: {},
}));

// Actions

export function setSsrApiData(data: Record<string, unknown>) {
  return ssrApiDataStore.setState({ data });
}

// Getters

export function onSsrApiDataSet(fn: () => void) {
  ssrApiDataStore.subscribe(() => fn());
}

export function findSsrApiDataUrl(
  fn: (url: string, data: unknown) => boolean,
): string | undefined {
  const { data } = ssrApiDataStore.getState();
  const entries = Object.entries(data);

  for (let i = 0; i < entries.length; i++) {
    const [url, ssrApiData] = entries[i];
    if (fn(url, ssrApiData)) return url;
  }
}

export function getSsrApiDataForUrl<TData>(
  url: string | undefined,
): TData | undefined {
  if (!url) return;

  return ssrApiDataStore.getState().data[url] as TData | undefined;
}

// Event listeners

onClientHydrated(() => {
  // Erase data to avoid caching between page changes
  setSsrApiData({});
});
