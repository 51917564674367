import queryString from 'query-string';
import { Navigate, RouteObject } from 'react-router-dom';

import { ApiCreditPackIdentifier } from 'api/types/ApiCreditPackIdentifier';
import { ApiOrgId } from 'api/types/ApiTypedId';
import { RequireAuthentication } from 'containers/RequireAuthentication';
import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { OrgDashboardOrgContextConsumerWaitUntilLoaded } from 'modules/orgDashboard/containers/OrgDashboardOrgContextConsumerWaitUntilLoaded';
import { OrgDashboardOrgContextProviderContainer } from 'modules/orgDashboard/containers/OrgDashboardOrgContextProviderContainer';
import ApiAccessContainerLoadable from 'modules/orgDashboard/pages/ApiAccess/ApiAccessContainerLoadable';
import { BuyCreditsContainerLoadable } from 'modules/orgDashboard/pages/BuyCredits/BuyCreditsContainerLoadable';
import { CartContainerLoadable } from 'modules/orgDashboard/pages/Cart/CartContainerLoadable';
import { CheckoutBillingAddressContainerLoadable } from 'modules/orgDashboard/pages/CheckoutBillingAddress/CheckoutBillingAddressContainerLoadable';
import { CreditViewContainerLoadable } from 'modules/orgDashboard/pages/CreditView/CreditViewContainerLoadable';
import CreditsListContainerLoadable from 'modules/orgDashboard/pages/CreditsList/CreditsListContainerLoadable';
import { OrgDashboardEditEventPageContainerLoadable } from 'modules/orgDashboard/pages/EditEvent/OrgDashboardEditEventPageContainerLoadable';
import { EditInternshipPageContainerLoadable } from 'modules/orgDashboard/pages/EditInternship/EditInternshipPageContainerLoadable';
import EditJobPageContainerLoadable from 'modules/orgDashboard/pages/EditJob/EditJobPageContainerLoadable';
import { EditOrgPageContainerLoadable } from 'modules/orgDashboard/pages/EditOrg/EditOrgPageContainerLoadable';
import { OrgDashboardEditVolopPageContainerLoadable } from 'modules/orgDashboard/pages/EditVolop/OrgDashboardEditVolopPageContainerLoadable';
import GradFairHomeContainerLoadable from 'modules/orgDashboard/pages/GradFairHome/GradFairHomeContainerLoadable';
import GradFairInviteContainerLoadable from 'modules/orgDashboard/pages/GradFairInvite/GradFairInviteContainerLoadable';
import IntegrationsPageContainerLoadable from 'modules/orgDashboard/pages/Integrations/IntegrationsPageContainerLoadable';
import InvoiceBillingAddressContainerLoadable from 'modules/orgDashboard/pages/InvoiceBilling/InvoiceBillingAddressContainerLoadable';
import { OrgDashboardInvoiceViewPageContainerLoadable } from 'modules/orgDashboard/pages/InvoiceView/OrgDashboardInvoiceViewPageContainerLoadable';
import { InvoicesListContainerLoadable } from 'modules/orgDashboard/pages/InvoicesList/InvoicesListContainerLoadable';
import { ManageAdminsPageContainerLoadable } from 'modules/orgDashboard/pages/ManageAdmins/ManageAdminsPageContainerLoadable';
import { ManageListingsPageLoadable } from 'modules/orgDashboard/pages/ManageListings/ManageListingsPageLoadable';
import NotificationPreferencesLoadable from 'modules/orgDashboard/pages/NotificationPreferences/NotificationPreferencesLoadable';
import { PayByCreditCardContainerLoadable } from 'modules/orgDashboard/pages/PayByCreditCard/PayByCreditCardContainerLoadable';
import { PaymentSummaryContainerLoadable } from 'modules/orgDashboard/pages/PaymentSummary/PaymentSummaryContainerLoadable';
import { PaymentViewContainerLoadable } from 'modules/orgDashboard/pages/PaymentView/PaymentViewContainerLoadable';
import PaymentsListContainerLoadable from 'modules/orgDashboard/pages/PaymentsList/PaymentsListContainerLoadable';
import { OrgDashboardRepostEventPageContainerLoadable } from 'modules/orgDashboard/pages/RepostEvent/OrgDashboardRepostEventPageContainerLoadable';
import { OrgDashboardRepostInternshipPageContainerLoadable } from 'modules/orgDashboard/pages/RepostInternship/OrgDashboardRepostInternshipPageContainerLoadable';
import { OrgDashboardRepostJobPageContainerLoadable } from 'modules/orgDashboard/pages/RepostJob/OrgDashboardRepostJobPageContainerLoadable';
import { OrgDashboardRepostVolopPageContainerLoadable } from 'modules/orgDashboard/pages/RepostVolop/OrgDashboardRepostVolopPageContainerLoadable';
import { OrgDashboardSidebarSubLayoutLoadable } from 'modules/orgDashboard/sub-layouts/Sidebar/OrgDashboardSidebarSubLayoutLoadable';
import {
  orgDashboardStoreLoadOrg,
  orgDashboardStoreLoadOrgOnce,
} from 'modules/orgDashboard/zustand-stores/orgDashboardOrgStore';
import { AppRouteGroupLoaderArgs } from 'routing/classes/types/AppRouteGroupLoaderArgs';
import { loadableLoader } from 'routing/utils/loadableLoader';
import { WithRouterParams } from 'utils/WithRouterParams';
import { getQueryParamStringArray } from 'utils/url/getQueryParamStringArray';

import { noSubLayoutOrgDashboardRouteObjects } from './noSubLayout.orgDashboard.route-objects';
import { noSubLayoutBaseOrgDashboardRouteObjects } from './noSubLayoutBase.orgDashboard.route-objects';
import {
  orgDashboardApiAccessRoute,
  orgDashboardBuyCreditPacksRoute,
  orgDashboardCartRoute,
  orgDashboardCheckoutBillingAddressRoute,
  orgDashboardCreditsRoute,
  orgDashboardEditEventRoute,
  orgDashboardEditInternshipRoute,
  orgDashboardEditJobRoute,
  orgDashboardEditOrgRoute,
  orgDashboardEditVolopRoute,
  orgDashboardGradFairsHomeRoute,
  orgDashboardIntegrationRoute,
  orgDashboardIntegrationsRoute,
  orgDashboardInvoiceBillingAddressRoute,
  orgDashboardListInvoicesRoute,
  orgDashboardListPaymentsRoute,
  orgDashboardManageAdminsRoute,
  orgDashboardManageListingsRoute,
  orgDashboardNotificationPreferencesRoute,
  orgDashboardPayByCreditCartRoute,
  orgDashboardPaymentSummaryRoute,
  orgDashboardRepostEventRoute,
  orgDashboardRepostInternshipRoute,
  orgDashboardRepostJobRoute,
  orgDashboardRepostVolopRoute,
  orgDashboardRouteGroup,
  orgDashboardViewCreditPackRoute,
  orgDashboardViewGradFairInviteRoute,
  orgDashboardViewInvoiceRoute,
  orgDashboardViewPaymentRoute,
} from './orgDashboard.routes';

export const orgDashboardRouteObjects = [
  {
    path: orgDashboardRouteGroup.wrapperPath,
    element: <OrgDashboardOrgContextProviderContainer />,
    loader: async ({
      params,
      request,
    }: AppRouteGroupLoaderArgs<typeof orgDashboardRouteGroup>) => {
      if (typeof window !== 'undefined') {
        // Force fetching the org to ensure we have the latest data
        const force = Boolean(
          request.url.match(
            new RegExp(orgDashboardEditOrgRoute.with({ orgId: params.orgId! })),
          ),
        );

        const orgId = params.orgId as ApiOrgId;
        if (force) await orgDashboardStoreLoadOrg(orgId);
        else await orgDashboardStoreLoadOrgOnce(orgId);
      }
      return null;
    },
    children: [
      // Org Dashboard Sidebar Sub Layout
      {
        element: (
          <RequireAuthentication>
            <OrgDashboardSidebarSubLayoutLoadable />
          </RequireAuthentication>
        ),
        loader: loadableLoader(OrgDashboardSidebarSubLayoutLoadable),
        handle: {
          fallbackUrlByLocaleToCurrent: true,
          darkBackground: true,
          showDesktopSearch: true,
        } satisfies LayoutHandleProps,
        children: [
          {
            path: orgDashboardManageListingsRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <ManageListingsPageLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardEditOrgRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <EditOrgPageContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardManageAdminsRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <ManageAdminsPageContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardEditJobRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <EditJobPageContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardRepostJobRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ jobId }) => (
                      <OrgDashboardRepostJobPageContainerLoadable
                        org={org}
                        jobId={jobId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardEditInternshipRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <EditInternshipPageContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardRepostInternshipRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ internshipId }) => (
                      <OrgDashboardRepostInternshipPageContainerLoadable
                        org={org}
                        internshipId={internshipId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardEditVolopRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ volopId }) => (
                      <OrgDashboardEditVolopPageContainerLoadable
                        org={org}
                        volopId={volopId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardRepostVolopRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ volopId }) => (
                      <OrgDashboardRepostVolopPageContainerLoadable
                        org={org}
                        volopId={volopId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardEditEventRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ eventId }) => (
                      <OrgDashboardEditEventPageContainerLoadable
                        org={org}
                        eventId={eventId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardRepostEventRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ eventId }) => (
                      <OrgDashboardRepostEventPageContainerLoadable
                        org={org}
                        eventId={eventId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardListInvoicesRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <InvoicesListContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
            // Don't revalidate when search params change, as they won't influence this loader
            shouldRevalidate: () => false,
          },
          {
            path: orgDashboardViewInvoiceRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ invoiceId }) => (
                      <OrgDashboardInvoiceViewPageContainerLoadable
                        org={org}
                        invoiceId={invoiceId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardInvoiceBillingAddressRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ invoiceId }) => (
                      <InvoiceBillingAddressContainerLoadable
                        org={org}
                        invoiceId={invoiceId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardViewPaymentRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ paymentId }) => (
                      <PaymentViewContainerLoadable
                        org={org}
                        paymentId={paymentId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardListPaymentsRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <PaymentsListContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
            // Don't revalidate when search params change, as they won't influence this loader
            shouldRevalidate: () => false,
          },
          {
            path: orgDashboardPaymentSummaryRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <PaymentSummaryContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardCheckoutBillingAddressRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={(params, { search }) => {
                      const searchValues = queryString.parse(search);
                      const invoiceId = searchValues.invoiceId as string;
                      const initialBillingAddressId =
                        searchValues.billingAddressId as string;
                      if (!invoiceId || !initialBillingAddressId) {
                        return <Navigate to="cart" />;
                      }
                      return (
                        <CheckoutBillingAddressContainerLoadable
                          org={org}
                          invoiceId={invoiceId}
                          initialBillingAddressId={initialBillingAddressId}
                        />
                      );
                    }}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardPayByCreditCartRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={(params, { search }) => (
                      <PayByCreditCardContainerLoadable
                        org={org}
                        invoiceIds={getQueryParamStringArray(
                          search,
                          'invoiceId',
                        )}
                        // @ts-expect-error TS(2322): Type 'number | "" | null' is not assignable to typ... Remove this comment to see the full error message
                        amount={
                          queryString.parse(search).amount &&
                          // @ts-expect-error TS(2345): Argument of type 'string | (string | null)[] | nul... Remove this comment to see the full error message
                          parseFloat(queryString.parse(search).amount)
                        }
                        // @ts-expect-error TS(2322): Type 'number | "" | null' is not assignable to typ... Remove this comment to see the full error message
                        invoiceTotal={
                          queryString.parse(search).invoiceTotal &&
                          // @ts-expect-error TS(2345): Argument of type 'string | (string | null)[] | nul... Remove this comment to see the full error message
                          parseFloat(queryString.parse(search).invoiceTotal)
                        }
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardCartRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <CartContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardBuyCreditPacksRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <BuyCreditsContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardCreditsRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <CreditsListContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardViewCreditPackRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ packId }) => (
                      <CreditViewContainerLoadable
                        org={org}
                        packId={packId as ApiCreditPackIdentifier}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardApiAccessRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <ApiAccessContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardIntegrationsRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <IntegrationsPageContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardIntegrationRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <IntegrationsPageContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardViewGradFairInviteRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => (
                  <WithRouterParams
                    render={({ inviteId }) => (
                      <GradFairInviteContainerLoadable
                        org={org}
                        inviteId={inviteId}
                      />
                    )}
                  />
                )}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardGradFairsHomeRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <GradFairHomeContainerLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
          {
            path: orgDashboardNotificationPreferencesRoute.relativePath,
            element: (
              <OrgDashboardOrgContextConsumerWaitUntilLoaded>
                {(org) => <NotificationPreferencesLoadable org={org} />}
              </OrgDashboardOrgContextConsumerWaitUntilLoaded>
            ),
          },
        ],
      },
      // No Sub Layout
      {
        handle: {
          fallbackUrlByLocaleToCurrent: true,
          darkBackground: true,
          showDesktopSearch: true,
        } satisfies LayoutHandleProps,
        children: noSubLayoutOrgDashboardRouteObjects,
      },
      // No Sub Layout, 'base' Layout variant (these need to be migrated)
      {
        handle: { variant: 'base' } satisfies LayoutHandleProps,
        children: noSubLayoutBaseOrgDashboardRouteObjects,
      },
    ],
  },
] satisfies RouteObject[];
