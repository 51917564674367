import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { CmsApiSearchLandingPage } from 'modules/cms/api/types/CmsApiSearchLandingPage';
import { getContentLocaleUrl } from 'modules/cms/helpers';

type Args = {
  content: CmsApiSearchLandingPage;
};

export function getCmsSearchLandingPagePageLayoutHandleProps({
  content,
}: Args): LayoutHandleProps {
  const urlByLocale = getContentLocaleUrl({ content });

  return {
    urlByLocale,
    darkBackground: true,
    defaultSearchType: content.filters.type,
    hideFooterDivider: true,
    showDesktopSearch: true,
    isSearchPage: true,
    isSearchLandingPage: true,
  };
}
