import { DividerHr } from './Divider.styled';

type Props = {
  dashed?: boolean;
  marginTop: number;
  marginBottom: number;
  opaque?: boolean;
};

export function Divider({ dashed, marginTop, marginBottom, opaque }: Props) {
  return (
    <DividerHr
      style={{
        borderBottomStyle: dashed ? 'dashed' : undefined,
        marginTop,
        marginBottom,
        opacity: opaque ? 0.2 : undefined,
      }}
    />
  );
}
