import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { ApiSavedSearch } from './types/ApiSavedSearch';

type ApiResponse = {
  savedSearch: ApiSavedSearch;
};

function mapResponse(response: ApiResponse): ApiSavedSearch {
  return response.savedSearch;
}

const endpoint = new FetchEndpoint({
  urlFactory: (savedSearchId: string) =>
    `/data/saved-searches/${savedSearchId}`,
  mapResponse,
});

// Exports

export const apiFetchSavedSearch = endpoint.createFetchFn();
