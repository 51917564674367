import { configureStore } from '@reduxjs/toolkit';

import { mainRootReducer } from './mainRootReducer';

export function configureMainStore(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preloadedState?: any,
) {
  return configureStore({
    preloadedState,
    reducer: mainRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  });
}
