import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

import { searchAlgoliaOptionsFilterForFacet } from './searchAlgoliaOptionsFilterForFacet';

type Args = {
  filtersById: SearchFiltersById;
  searchFacets: SearchFacet[];
};

export function searchAlgoliaOptionsFiltersForFacets({
  filtersById,
  searchFacets,
}: Args): string[] {
  const queryStringValues: string[] = [];

  searchFacets.forEach((searchFacet) => {
    const rawValue = filtersById[searchFacet.id];
    if (!rawValue) return;

    const value = searchAlgoliaOptionsFilterForFacet({ searchFacet, rawValue });
    if (!value) return;

    queryStringValues.push(value);
  });

  return queryStringValues;
}
