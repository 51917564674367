import { ReactNode, useCallback, useRef } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { getViewportBreakpoint } from 'utils/viewport/getViewportBreakpoint';

import { DropdownDetails, DropdownSummary } from './Dropdown.styled';
import { useDropdownToggleObserver } from './useDropdownToggleObserver';
import { useDropdownTransitionClasses } from './useDropdownTransitionClasses';
import { useDropdownTriggerOnHover } from './useDropdownTriggerOnHover';

type Props = {
  /**
   * Dropdown uses `<summary>` + `<details>` so the trigger should not be a `<button>`
   * or other element that hijacks the `onClick` event
   */
  trigger: ReactNode;
  children: ReactNode;
  qaId?: string;
  className?: string;
  onToggle?: (open: boolean) => void;
  desktopTriggersOnHover?: boolean;
};

export function Dropdown({
  trigger,
  children,
  qaId,
  className,
  onToggle,
  desktopTriggersOnHover,
}: Props) {
  const detailsElRef = useRef<HTMLDetailsElement>(null);

  const close = useCallback(() => {
    const detailsEl = detailsElRef.current;
    if (!detailsEl) return;

    detailsEl.open = false;
  }, []);

  const { onToggleAnimation } = useDropdownTransitionClasses({ detailsElRef });

  const toggle = useCallback(
    (open: boolean) => {
      onToggleAnimation(open);
      onToggle?.(open);
    },
    [onToggle, onToggleAnimation],
  );

  useOnClickOutside({
    elementRef: detailsElRef,
    onClickOutside: () => {
      // Wait just a little bit because when going from one dropdown to another,
      // closing the previous dropdown gives a flickering impression
      setTimeout(close, 25);
    },
  });

  useDropdownToggleObserver({ detailsElRef, onToggle: toggle });

  useDropdownTriggerOnHover({
    detailsElRef,
    triggerOnHover: Boolean(
      global.window &&
        ['md', 'lg'].includes(getViewportBreakpoint()) &&
        desktopTriggersOnHover,
    ),
  });

  return (
    <DropdownDetails className={className} ref={detailsElRef} data-qa-id={qaId}>
      <DropdownSummary>{trigger}</DropdownSummary>

      {children}
    </DropdownDetails>
  );
}
