import styled from 'styled-components';

import { dropdownOneWhenClosedMinusOneWhenOpenVar } from 'components/Dropdown/dropdownCssVariables';
import { transition } from 'theme/theme';

export const DropdownChevronHolder = styled.div`
  margin-left: 5px;
  transition: ${transition};
  transform: scaleY(var(${dropdownOneWhenClosedMinusOneWhenOpenVar}, 1));
`;
