import { UseFetchFnMeta } from 'hooks/useFetchFn';
import { useFetchUrl } from 'hooks/useFetchUrl';
import { getJson } from 'utils/http/getJson';
import { isRenderingServer } from 'zustand-stores/renderingStateStore';
import { getSsrApiDataForUrl } from 'zustand-stores/ssrApiDataStore';

type FetchEndpointUrlFactory<TArgs extends unknown[], TUrl extends string> = (
  ...args: TArgs
) => TUrl | undefined;

type FetchEndpointResponseMapper<TMappedResponse, TResponse> = (
  response: TResponse,
) => TMappedResponse;

export class FetchEndpoint<
  TArgs extends unknown[],
  TUrl extends string,
  TMappedResponse,
  TResponse,
> {
  urlFactory: FetchEndpointUrlFactory<TArgs, TUrl>;

  mapResponse: FetchEndpointResponseMapper<TMappedResponse, TResponse>;

  constructor(args: {
    urlFactory: FetchEndpointUrlFactory<TArgs, TUrl>;
    mapResponse: FetchEndpointResponseMapper<TMappedResponse, TResponse>;
  }) {
    this.urlFactory = args.urlFactory;
    this.mapResponse = args.mapResponse;
  }

  url(...args: TArgs): TUrl | undefined {
    return this.urlFactory(...args);
  }

  createFetchFn() {
    return async (...args: TArgs): Promise<TMappedResponse> => {
      const url = this.urlFactory(...args);
      if (!url) throw new Error('Cannot fetch with undefined URL');

      // This is a server request
      if (isRenderingServer()) {
        const data = getSsrApiDataForUrl(url);
        if (!data)
          throw new Error(`Data for ${url} is not available during SSR`);

        return this.mapResponse(data as TResponse);
      }

      return this.mapResponse(await getJson<TResponse>(url));
    };
  }

  createUseFetchHook() {
    return (
      ...args: TArgs
    ): [TMappedResponse | undefined, UseFetchFnMeta<TMappedResponse>] => {
      const url = this.url(...args);

      return useFetchUrl({ url, mapResponse: this.mapResponse });
    };
  }
}
