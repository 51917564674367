import interpolateComponents from '@automattic/interpolate-components';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { trackAnchorClicked } from 'utils/analytics/track/trackAnchorClicked';

import { AcceptCookiesContainer } from './AcceptCookies.styled';

type Props = {
  onAcceptCookies: () => void;
  submitting: boolean;
};

export function AcceptCookies({ onAcceptCookies, submitting }: Props) {
  return (
    <AcceptCookiesContainer data-qa-id="accept-cookies-alert">
      <div>
        {interpolateComponents({
          mixedString:
            /* TRANSLATORS: accept cookie message in page footer for EU */
            getText(
              'This site uses cookies. By continuing to use our site, you agree to our use of cookies as described in our {{link}}Cookie Policy{{/link}}.',
            ),
          components: {
            link: (
              <StyledNativeLinkWithRef
                href="https://info.idealist.org/cookie-policy/"
                onClick={(event) => trackAnchorClicked(event, 'Resource Page')}
              />
            ),
          },
        })}
      </div>

      <Box ml={[0, 0, 0, 3]} mt={[2, 0, 0, 0]}>
        <Button
          type="button"
          variant="primary"
          size="small"
          onClick={onAcceptCookies}
          disabled={submitting}
          data-qa-id="accept-cookies-button"
        >
          {
            /* TRANSLATORS: accept cookies button text */
            getText('OK')
          }
        </Button>
      </Box>
    </AcceptCookiesContainer>
  );
}
