import { Store } from '@reduxjs/toolkit';

import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';

export function renderingGetClientPreloadedUserEnvironment(
  store: Store,
): ApiUserEnvironment | undefined {
  const { initUserEnvironment } = window.Idealist;
  if (initUserEnvironment) return initUserEnvironment;

  const { userEnvironment } = store.getState();
  if (userEnvironment?.isUserLoaded && userEnvironment?.isEnvironmentLoaded) {
    return userEnvironment;
  }
}
