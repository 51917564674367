import { searchAlgoliaOptionsFiltersForFacets } from 'modules/search/algolia/options/searchAlgoliaOptionsFiltersForFacets';
import { searchAlgoliaIndex } from 'modules/search/algolia/searchAlgoliaIndex';
import { searchFacetsInternship } from 'modules/search/constants/searchFacetsInternship';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchSortType } from 'modules/search/types/SearchSortType';
import { algoliaQueryStringFilter } from 'utils/algolia/helpers/algoliaQueryStringFilter';
import { AlgoliaSearchOptionsWithIndex } from 'utils/algolia/types/AlgoliaSearchOptionsWithIndex';
import { SEARCH_PAGE_SIZE } from 'utils/constants/general/searchPageSize';

const attributesToRetrieve = [
  'objectID',
  'type',
  'published',
  'name',
  'city',
  'state',
  'stateStr',
  'country',
  'url',
  'orgID',
  'orgUrl',
  'orgName',
  'orgType',
  'jobType',
  'logo',
  'locationType',
  'remoteZone',
  'paid',
  'payment',
  '_geoloc',
  'localizedStarts',
  'localizedEnds',
  'hasPublishedListings',
  'actionType',
  'source',
  'timezone',
  'hasAts',
  'isPostedAnonymously',
  'hiring',
  'salaryMinimum',
  'salaryMaximum',
  'salaryCurrency',
  'salaryPeriod',
];

type Args = {
  filtersById: SearchFiltersById;
  sort: SearchSortType;
};

export function searchAlgoliaInternshipsOptions({
  filtersById,
  sort,
}: Args): AlgoliaSearchOptionsWithIndex {
  return {
    index: searchAlgoliaIndex(sort),
    facets: ['*'],
    hitsPerPage: SEARCH_PAGE_SIZE,
    attributesToSnippet: ['description:20'],
    attributesToRetrieve,
    filters: [
      algoliaQueryStringFilter('type', 'INTERNSHIP'),
      ...searchAlgoliaOptionsFiltersForFacets({
        filtersById,
        searchFacets: searchFacetsInternship,
      }),
    ].join(' AND '),
    removeStopWords: true,
    ignorePlurals: true,
    advancedSyntax: true,
    queryLanguages: ['en', 'es', 'pt'],
  };
}
