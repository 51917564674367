import { ReactNode, Suspense } from 'react';

import { ClientOnly } from 'containers/ClientOnly';

type Props = {
  children?: ReactNode;
  fallback?: ReactNode;
};

export function ClientOnlySuspense({ children, fallback = null }: Props) {
  return (
    <ClientOnly placeholder={fallback}>
      <Suspense fallback={fallback}>{children}</Suspense>
    </ClientOnly>
  );
}
