import { useCallback } from 'react';

import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';
import { MainStoreUserEnvironmentState } from 'store/ducks/userEnvironment';
import { updateUserEnvironment as reduxUpdateUserEnvironment } from 'store/ducks/userEnvironment.actions';

import { useMainDispatch } from './useMainDispatch';
import { useMainSelector } from './useMainSelector';

export function useUserEnvironment(): MainStoreUserEnvironmentState & {
  updateUserEnvironment: (userEnvironment: ApiUserEnvironment) => void;
} {
  const dispatch = useMainDispatch();

  const updateUserEnvironment = useCallback(
    (userEnvironment: ApiUserEnvironment) => {
      dispatch(reduxUpdateUserEnvironment(userEnvironment));
    },
    [dispatch],
  );

  const userEnvironment = useMainSelector((state) => state.userEnvironment);

  return { ...userEnvironment, updateUserEnvironment };
}
