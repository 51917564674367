import styled, { css } from 'styled-components';

import {
  dropdownBrandBlueWhenOpenVar,
  dropdownCalloutGreyWhenOpenVar,
  dropdownOneWhenClosedZeroWhenOpenVar,
} from 'components/Dropdown/dropdownCssVariables';
import { colors, cssBreakpoints, transition } from 'theme/theme';

const pageHeaderButtonBlueVariantCss = css`
  color: ${colors.white};
  background: ${colors.brandBlue};
  outline-color: ${colors.hoverBlue};

  &:hover {
    color: ${colors.white};
    background: ${colors.hoverBlue};
  }
`;

const pageHeaderDropdownTriggerGreyVariantCss = css`
  color: var(${dropdownBrandBlueWhenOpenVar}, ${colors.darkContentGrey});
  background: var(${dropdownCalloutGreyWhenOpenVar});

  &:hover {
    background: ${colors.selectionGrey};
  }

  &:is(a):hover {
    color: ${colors.brandBlue};
  }
`;

const pageHeaderDropdownTriggerSecondaryVariantCss = css`
  border: 1px solid ${colors.brandBlue};
  color: ${colors.brandBlue};
  background: var(${dropdownCalloutGreyWhenOpenVar});

  &:hover {
    background-color: ${colors.softBlue};
  }

  &:is(a):hover {
    color: ${colors.brandBlue};
  }
`;

export const HeaderDropdownTriggerHolder = styled.div<{
  $variant: 'blue' | 'grey' | 'secondary';
  $smallHorizontalPadding: boolean;
  $roundBottomWhenOpen: boolean;
  $centerHorizontally: boolean;
}>`
  color: ${colors.white};
  transition: ${transition};
  display: inline-flex;
  align-items: center;
  height: 38px;
  padding: 0 ${(props) => (props.$smallHorizontalPadding ? 6 : 12)}px;
  font-size: 15px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  > * {
    /* Avoid mouseover triggering while when hovering over other elements */
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  justify-content: ${(props) =>
    props.$centerHorizontally ? 'center' : 'space-between'};

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    border-radius: 6px 6px
      ${(props) =>
        props.$roundBottomWhenOpen
          ? `calc(var(${dropdownOneWhenClosedZeroWhenOpenVar}, 1) * 6px) calc(var(${dropdownOneWhenClosedZeroWhenOpenVar}, 1) * 6px)`
          : ''};
  }

  ${(props) => {
    switch (props.$variant) {
      case 'blue':
        return pageHeaderButtonBlueVariantCss;
      case 'grey':
        return pageHeaderDropdownTriggerGreyVariantCss;
      case 'secondary':
        return pageHeaderDropdownTriggerSecondaryVariantCss;
    }
  }};
`;
