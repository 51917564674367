import { RouteObject } from 'react-router-dom';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiFetchEvent } from 'modules/listing/api/event/listingApiFetchEvent';
import { ViewEventPageLoadable } from 'modules/listing/pages/ViewEvent/ViewEventPageLoadable';
import { ViewUnpublishedEventPageLoadable } from 'modules/listing/pages/ViewUnpublishedEvent/ViewUnpublishedEventPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import { eventSegmentedRoutes } from './event.routes';

/**
 * Event routes
 *
 * These routes are separate in their own file to facilitate maintenance, and
 * they're used on `main.route-objects.tsx`
 */
export const eventRouteObjects = eventSegmentedRoutes.map((segmentedRoute) => ({
  path: segmentedRoute.fullPath,
  ...renderLoader({
    loader: async ({ params }: AppRouteLoaderArgs<typeof segmentedRoute>) => {
      const { eventIdDashSlug = '' } = params;

      const {
        userEnvironment: { user },
      } = global.store.getState();

      try {
        const [event] = await Promise.all([
          listingApiFetchEvent(eventIdDashSlug.split('-')[0]),
          // It's possible this loadable won't be used if the event is unpublished
          // but in most cases optimistically loading it is best
          ViewEventPageLoadable.load(),
        ]);

        switch (event.type) {
          case 'EVENT':
            return {
              type: event.type,
              event,
              handle: {
                hideFooterDivider: Boolean(!user),
                urlByLocale: event.url,
              } satisfies LayoutHandleProps,
            };
          case 'UNPUBLISHED_EVENT':
            await ViewUnpublishedEventPageLoadable.load();
            return {
              type: event.type,
              unpublishedEvent: event,
            };
        }
      } catch {
        return null;
      }
    },
    render: (data) => {
      switch (data?.type) {
        case undefined:
          return <NotFoundPageLoadable noLayout />;
        case 'EVENT':
          return <ViewEventPageLoadable event={data.event} />;
        case 'UNPUBLISHED_EVENT':
          return (
            <ViewUnpublishedEventPageLoadable
              unpublishedEvent={data.unpublishedEvent}
            />
          );
      }
    },
  }),

  handle: {
    defaultSearchType: 'EVENT',
    showDesktopSearch: true,
  } satisfies LayoutHandleProps,
})) satisfies RouteObject[];
