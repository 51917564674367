import { create } from 'zustand';

const renderingStateStore = create<{
  state: 'server' | 'client-not-hydrated' | 'client-hydrated';
}>(() => ({
  state: typeof window === 'undefined' ? 'server' : 'client-not-hydrated',
}));

// Actions

export function setClientHydrated() {
  renderingStateStore.setState({ state: 'client-hydrated' });
}

// Hooks

export function isRenderingServer() {
  return renderingStateStore.getState().state === 'server';
}

export function isClientHydrated() {
  return renderingStateStore.getState().state === 'client-hydrated';
}

export function onClientHydrated(fn: () => void) {
  if (isClientHydrated()) {
    fn();
    return;
  }

  const unsubscribe = renderingStateStore.subscribe((store) => {
    if (store.state === 'client-hydrated') {
      fn();
      unsubscribe();
    }
  });
}
