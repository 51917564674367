import { clientOnlyLazy } from 'components/ClientOnlySuspense/clientOnlyLazy';

// eslint-disable-next-line import/no-default-export
export default clientOnlyLazy({
  loader: () =>
    import(
      /* webpackChunkName: "orgDashboard/EditJobPageContainer" */
      './EditJobPageContainer'
    ),
});
