import { ReactNode } from 'react';

import { Box } from 'components/Box';
import { Icon } from 'components/Icon/Icon';
import { OptionType } from 'components/Select/Select.types';

import { StyledSelect } from './SelectInput.styled';

type Props = {
  'aria-label'?: string;
  id?: string;
  label?: ReactNode | string;
  className?: string;
  name?: string;
  disabled?: boolean;
  value?: OptionType['value'];
  defaultValue?: OptionType['value'];
  options: ReadonlyArray<OptionType & { disabled?: boolean }>;
  onChange?: (value: OptionType['value']) => void;
  'data-qa-id'?: string;
  hasError?: boolean;
  small?: boolean;
  required?: boolean;
  placeholder?: string;
  width?: string | number;
  height?: string | number;
};

export function SelectInput({
  disabled,
  name,
  value,
  defaultValue,
  options,
  onChange,
  hasError,
  small,
  ...props
}: Props) {
  return (
    <Box position="relative">
      <StyledSelect
        disabled={disabled || options.length <= 1}
        $optionCount={options.length}
        name={name}
        $small={small}
        value={value}
        defaultValue={defaultValue}
        onChange={
          // TODO: remove `value` - without `value` this causes an error during the rendering of `SearchFilterSectionRecencySelect`
          value || onChange ? (e) => onChange?.(e.target.value) : undefined
        }
        $hasError={hasError}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {options.map(({ value: optionValue, label, ...optionProps }) => (
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/jsx-props-no-spreading
          <option key={optionValue} value={optionValue} {...optionProps}>
            {label}
          </option>
        ))}
      </StyledSelect>
      {options.length > 1 ? (
        <Box
          position="absolute"
          top="50%"
          right={small ? 12 : 19}
          transform="translateY(-50%)"
          pointerEvents="none"
        >
          <Icon name="chevron-down" size={small ? 10 : 16} />
        </Box>
      ) : null}
    </Box>
  );
}
