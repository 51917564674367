import { HttpError } from 'utils/HttpError';

// eslint-disable-next-line import/no-cycle
import { handleErrors } from './handleErrors';
import { parseJsonResponse } from './parseJsonResponse';

export function getJsonInternal<TData>(
  url: string,
  resolve: (data: TData) => void,
  reject: (error: Error) => void,
  retriesRemaining: number,
) {
  const xhr = new XMLHttpRequest();
  xhr.addEventListener('load', () => {
    handleErrors(
      xhr.status,
      url,
      () => resolve(parseJsonResponse(xhr, reject)),
      () => reject(new HttpError('GET', url, xhr)),
      {
        retriesRemaining,
      },
    );
  });
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.send();
}
