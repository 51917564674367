import { NonNullableFields } from 'types/NonNullableFields';
import { del } from 'utils/http/del';
import { getJson } from 'utils/http/getJson';
import { postJson } from 'utils/http/postJson';

import { ApiUserEnvironment } from './types/ApiUserEnvironment';

export function apiFetchUserEnvironment(): Promise<ApiUserEnvironment> {
  return getJson<ApiUserEnvironment>(`/data/environment`);
}

export function canDeleteAccount(): Promise<{
  isLastOwnerOfOrgs: Array<{
    id: string;
    name: string;
    hasOutstandingInvoices: boolean;
    credits: number;
  }>;
}> {
  return getJson('/data/users/me/can-delete');
}

export function deleteAccount() {
  return del('/data/users/me/delete');
}

export async function signUp(
  userInfo: Record<string, unknown>,
): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson('/data/website/signup', userInfo);
}

export async function logIn(
  loginInfo: Record<string, unknown>,
): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson('/data/website/login', loginInfo);
}

export function forgotPassword(email: string) {
  return postJson('/data/website/forgot-password', {
    email,
  });
}

export async function resetPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<NonNullableFields<ApiUserEnvironment>> {
  return postJson(`/data/website/reset-password/${token}`, {
    password,
  });
}
