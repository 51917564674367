import { Store } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { StaffStoreState } from 'modules/staff/store/StaffStoreState';
import { MainStoreState } from 'store/MainStoreState';

type Props = {
  store: Store<MainStoreState | StaffStoreState>;
  children?: ReactNode;
};

export function RenderingWrapper({ store, children }: Props) {
  return <Provider store={store}>{children}</Provider>;
}
