import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { CmsApiPagePage } from 'modules/cms/api/types/CmsApiPage';
import {
  getContentLocaleUrl,
  getHideFooterDivider,
  getIsNoAdPage,
} from 'modules/cms/helpers';
import { getCmsApiPageDefaultSearchType } from 'modules/cms/helpers/getCmsApiPageDefaultSearchType';

type Args = {
  loggedIn: boolean;
  content: CmsApiPagePage;
};

export function getCmsPagePageLayoutHandleProps({
  loggedIn,
  content,
}: Args): LayoutHandleProps {
  const defaultSearchType = getCmsApiPageDefaultSearchType({ content });

  // banner ads should not be on some pages
  const noAds = getIsNoAdPage(content, loggedIn);

  const hero = content.heroSearch?.onlyResults ? undefined : content.heroSearch;

  const hideFooterDivider = getHideFooterDivider({ content });
  const localeUrl = getContentLocaleUrl({ content });

  return {
    noAds,
    defaultSearchType,
    hideFooterDivider,
    urlByLocale: localeUrl,
    showDesktopSearch: !hero,
  };
}
