import styled, { css } from 'styled-components';

import { SelectInput } from 'components/SelectInput';
import { colors } from 'theme/theme';

const greyVariantCss = css`
  background: ${colors.inputGrey};
  color: ${colors.brandBlue};

  /* stylelint-disable-next-line selector-class-pattern */
  .react-select__control {
    border: none;
    background: ${colors.inputGrey};
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .react-select__single-value {
    color: ${colors.brandBlue};
  }
`;

const outlineBlueVariantCss = css`
  color: ${colors.brandBlue};
  border: 1px solid ${colors.elementGrey};

  /* stylelint-disable-next-line selector-class-pattern */
  .react-select__control {
    border: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .react-select__single-value {
    color: ${colors.brandBlue};
  }
`;

export const ListingTypeSelectStyled = styled(SelectInput)<{
  $variant: 'grey' | 'outline-blue';
}>`
  border-radius: 8px;
  border: none;

  ${(props) =>
    props.$variant === 'grey' ? greyVariantCss : outlineBlueVariantCss}
`;
