import { HttpError } from 'utils/HttpError';

import { handleErrors } from './handleErrors';

export function del<TData = unknown>(url: string) {
  return new Promise<TData>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      handleErrors(
        xhr.status,
        url,
        () => {
          if (xhr.responseText) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
            resolve();
          }
        },
        () => reject(new HttpError('DELETE', url, xhr)),
      );
    });
    xhr.open('DELETE', url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.send();
  });
}
