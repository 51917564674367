import { ComponentProps, ComponentType, lazy } from 'react';

import { LoadingSection } from 'components/LoadingSection/LoadingSection';

import { ClientOnlySuspense } from './ClientOnlySuspense';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Args<T extends ComponentType<any>> = {
  loader: () => Promise<{ default: T }>;
  loading?: ComponentType<Record<never, never>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clientOnlyLazy<T extends ComponentType<any>>({
  loader,
  loading: Loading,
}: Args<T>) {
  const Component = lazy(loader);

  return function ClientOnlySuspenseWrapper(props: ComponentProps<T>) {
    return (
      <ClientOnlySuspense fallback={Loading ? <Loading /> : <LoadingSection />}>
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </ClientOnlySuspense>
    );
  };
}
