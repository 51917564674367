import { useCallback, useState } from 'react';

import { AcceptCookies } from 'components/AcceptCookies/AcceptCookies';
import { useIsClient } from 'hooks/useIsClient';
import { updateUserEnvironmentEnvironment } from 'store/ducks/userEnvironment.actions';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { postJson } from 'utils/http/postJson';

export function AcceptCookiesContainer() {
  const [submitting, setSubmitting] = useState(false);

  const { isUserLoaded, isEnvironmentLoaded, environment } =
    useUserEnvironment();
  const dispatch = useMainDispatch();
  const isClient = useIsClient();

  const acceptCookies = useCallback(async () => {
    if (!isUserLoaded || !isEnvironmentLoaded || !environment) return;

    setSubmitting(true);

    await postJson('/data/website/accept-cookies');
    dispatch(
      updateUserEnvironmentEnvironment({
        ...environment,
        showAcceptCookies: false,
      }),
    );
    setSubmitting(false);
  }, [isUserLoaded, isEnvironmentLoaded, environment, dispatch]);

  if (!isEnvironmentLoaded || !environment?.showAcceptCookies || !isClient)
    return null;

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 9999,
      }}
    >
      <AcceptCookies onAcceptCookies={acceptCookies} submitting={submitting} />
    </div>
  );
}
