import { useCallback, useMemo } from 'react';

import { getJson } from 'utils/http/getJson';
import { getSsrApiDataForUrl } from 'zustand-stores/ssrApiDataStore';

import { UseFetchFnMeta, useFetchFn } from './useFetchFn';

type Args<TResponse, TMappedResponse> = {
  url: string | undefined;
  mapResponse: (data: TResponse) => TMappedResponse;
};

/**
 * A wrapper around `useFetchFn` that takes in an `url` and `mapResponse`.
 *
 * This hook uses `SsrApiDataContext` to initialize the cache, this
 * technique allows for transferring data from our Python backend to Hypernova
 * during Server Side Rendering and then hydrating the same data on the client.
 */
export function useFetchUrl<TResponse, TMappedResponse>({
  url,
  mapResponse,
}: Args<TResponse, TMappedResponse>): [
  TMappedResponse | undefined,
  UseFetchFnMeta<TMappedResponse>,
] {
  const initialCacheStorageData = useMemo(() => {
    const ssrApiData = getSsrApiDataForUrl<TResponse>(url);
    if (!ssrApiData || !url) return {};

    const mappedSsrApiData = mapResponse(ssrApiData);
    return { [url]: mappedSsrApiData };
  }, [mapResponse, url]);

  const fetchAndMap = useCallback(
    async (urlToFetch: string | undefined) => {
      if (typeof urlToFetch === 'undefined') return;

      const response = await getJson<TResponse>(urlToFetch);
      return mapResponse(response);
    },
    [mapResponse],
  );

  const [mappedResponse, meta] = useFetchFn({
    fn: fetchAndMap,
    args: [url],
    initialCacheStorageData,
    cacheKey: url,
  });

  return [mappedResponse, meta];
}
