import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { ACTION_TYPE_PLURAL } from 'utils/constants/general/actionTypePlural';
import { removeKeys } from 'utils/functional';

export const actionTypeFacet = {
  id: 'actionTypeFacet',
  name: 'actionType',
  title: getText('Opportunity Type'),
  type: 'array',
  options: ACTION_TYPE_PLURAL.filter(
    (actionType) => actionType.id !== 'LOAN',
  ).map((actionType) => actionType.id),
  optionTitles: removeKeys<string, string>(
    levelOptionTitles(ACTION_TYPE_PLURAL),
    ['NONE', 'OTHER', 'LOAN'],
  ),
  facetToResetOnChange: 'volopSourceFacet',
} as const satisfies SearchFacetData;
