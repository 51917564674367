import { addFlashMessageForNextPageLoad } from 'utils/cookies';

// eslint-disable-next-line import/no-cycle
import { getJsonInternal } from './getJsonInternal';

export function handleErrors(
  status: number,
  url: string,
  resolve: () => void,
  reject: () => void,
  options: { skipRedirects?: boolean; retriesRemaining?: number } = {},
) {
  if (status > 199 && status < 300) {
    resolve();
    return;
  }

  if (options.skipRedirects) {
    reject();
    return;
  }

  let loginPath = '/login';
  let homePath = `/${CURRENT_LOCALE}/`;

  if (window.location.pathname.startsWith('/fair')) {
    homePath = '/fair';
    loginPath = '/fair/login';
  }

  if (/\/[^/]/.test(url)) {
    // don't redirect on 401/403 for algolia requests
    if (status === 401) {
      addFlashMessageForNextPageLoad('LOGIN_REQUIRED');
      window.location.href = loginPath;
      return;
    }

    if (status === 403) {
      addFlashMessageForNextPageLoad('FORBIDDEN');
      window.location.href = homePath;
      return;
    }

    const { retriesRemaining } = options;
    if (
      (status === 408 ||
        /* Request Timeout */
        status >= 500) &&
      retriesRemaining
    ) {
      setTimeout(() => {
        getJsonInternal(url, resolve, reject, retriesRemaining - 1);
      }, 1000);
      return;
    }

    reject();
    return;
  }

  reject();
}
