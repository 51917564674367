import { cmsApiFetchPage } from 'modules/cms/api/cmsApiFetchPage';
import { CmsContentPageLoadable } from 'modules/cms/containers/ContentPage/CmsContentPageLoadable';
import { getCmsSearchLandingPagePageLayoutHandleProps } from 'modules/cms/helpers/getCmsSearchLandingPagePageLayoutHandleProps';
import { CmsPagePageLoadable } from 'modules/cms/pages/Page/CmsPagePageLoadable';
import { getCmsPagePageLayoutHandleProps } from 'modules/cms/pages/Page/getCmsPagePageLayoutHandleProps';
import { CmsPostPageLoadable } from 'modules/cms/pages/Post/CmsPostPageLoadable';
import { getCmsPagePostLayoutHandleProps } from 'modules/cms/pages/Post/getCmsPagePostLayoutHandleProps';
import { PostSearchPageLoadable } from 'modules/postSearch/pages/PostSearch/PostSearchPageLoadable';
import { getPostSearchPageLayoutHandleProps } from 'modules/postSearch/pages/PostSearch/getPostSearchPageLayoutHandleProps';
import { SearchLandingPageLoadable } from 'modules/search/pages/Landing/SearchLandingPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { TypedLoaderFunctionArgs } from 'routing/types/TypedLoaderFunctionArgs';

import { CmsPageLoaderData } from './CmsPageLoaderData';

export async function cmsPageLoader({
  request,
  params,
}: TypedLoaderFunctionArgs<'*'>): Promise<CmsPageLoaderData> {
  const slugParam = params['*'] as string;
  const slug = slugParam.replace('events', 'volunteer');

  const { searchParams } = new URL(request.url);

  const storeState = global.store.getState();
  const loggedIn = Boolean(storeState.userEnvironment.user);

  try {
    const content = await cmsApiFetchPage(slug);

    // FREE_CODE blocks with <script> tags won't be properly executed in
    // dynamically loaded pages due to browser security restrictions, so we need
    // to reload the page in that case. Previous discussion:
    // https://idealist.slack.com/archives/CHJ9JK7TP/p1730383641821849
    if (
      typeof global.window !== 'undefined' &&
      'contentBlocks' in content &&
      content.contentBlocks?.some((block) => block.type === 'FREE_CODE')
    ) {
      const requestUrl = new URL(request.url);
      const url = requestUrl.pathname + requestUrl.search + requestUrl.hash;

      return { type: 'CONTENT_FULL_RELOAD', url };
    }

    if (content.type === 'SEARCH_LANDING_PAGE') {
      await SearchLandingPageLoadable.load();
      return {
        type: 'SEARCH_LANDING_PAGE',
        content,
        isSsr: typeof global.window === 'undefined',
        handle: getCmsSearchLandingPagePageLayoutHandleProps({ content }),
      };
    }

    if (searchParams.has('pq')) {
      await PostSearchPageLoadable.load();
      return {
        type: 'POST_SEARCH',
        content,
        handle: getPostSearchPageLayoutHandleProps({ content }),
      };
    }

    if (content.type === 'PAGE') {
      await Promise.all([
        CmsContentPageLoadable.load(),
        CmsPagePageLoadable.load(),
      ]);

      return {
        type: 'CONTENT',
        content,
        handle: getCmsPagePageLayoutHandleProps({ loggedIn, content }),
      };
    }

    // content.type === 'POST'
    await Promise.all([
      CmsContentPageLoadable.load(),
      CmsPostPageLoadable.load(),
    ]);

    return {
      type: 'CONTENT',
      content,
      handle: getCmsPagePostLayoutHandleProps({ loggedIn, content }),
    };
  } catch {
    try {
      await NotFoundPageLoadable.load();
    } catch {}

    return null;
  }
}
